import PageLayout from "../components/Shared/PageLayout";
import * as React from "react";
import { EDPageLoader, EEDAnimation, IEDPageParallaxBackgroundImage } from "@trailblazer-game/ed-webkit";
import dynamic from "next/dynamic";

const NotFound = dynamic(() => import("../components/Pages/NotFound/NotFound"), {
    suspense: false,
    ssr: false,
    loading: () => <EDPageLoader in={true} />,
})


const PARALLAX_IMAGES: IEDPageParallaxBackgroundImage[] = [
    {
        src: "https://cdn.eternaldragons.com/assets/404_page.jpg",
        size: "cover",
        position: "center",
        animation: {
            animations: [ EEDAnimation.FadeIn ],
            ease: 'ease-in'
        }
    }
];

export default function Page404() {
    return <PageLayout backgroundImages={PARALLAX_IMAGES}>
        <NotFound />
    </PageLayout>
}

Page404.metaData = {
    title: 'Eternal Dragons Universe - Not found'
}